$small: 300px;
$medium: 1020px;
$mobile: 412px;

.width {
  max-width: 100%;
  width: 100%;
}
main {
  height: 100%;
  width: 100%;
 margin: 1rem;
}

@media screen and (max-width: 912px) {
  .sidebar {
    height: auto !important;
  }

  @media (max-width: 767px) {
    .icon,
    .link_text {
      font-size: 16px !important;
    }
  }
}

// BottomNavigator.scss

@media (max-width: 600px) {
  .sidebar {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .web {
    display: flex;
  }
  .side-bar-active {
    width: 200px;
  }
  .side-bar-close {
    width: 80px;
  }
}
