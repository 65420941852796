@import "../../../../SaasApplication/src/Sass/partial/variable";


.link-header {
  display: flex;
  color: #8b8b8b;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.link-header.active {
  color: #15729c;
  font-size: 20px;
  transition: all 0.5s;
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media (min-width: 600px) {
  .mobile-link {
    display: none !important;
  }
  .link-header {
    display: none;
  }
}

@media (max-width: 600px) {
  .navbar-toggler {
    border: none !important;
  }
}
