@media (min-width: 1400px) {
  .container {
    /* max-width: 100% !important; */
  }
}

@media (min-width: 412px) {
  .container {
    /* max-width: 100% !important; */
  }
}

/* scroll bar css */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}



.c-nav .navbar-toggler {
  border: none;
}


@media screen and (max-width: 700px) {
  .offcanvas-body {
    border-top: #898585;
    border-style: ridge;
    border-width: 1px 0px 0px 0px;
  }
}

.table-hover>tbody>tr:hover>* {

  --bs-table-bg-state: none !important;
}


.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  width: inherit !important;
}

.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
  text-align: end !important;
}

#transectiontable .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: none !important;
  width: fit-content !important;
}

.input-bg {
  background-color: #efebeb !important;
}

.donation-approval-swal .swal-title {
  font-size: 18px;
  font-weight: normal;
}

.hover-link {
  text-decoration: none;
  color: black;
}

.hover-link:hover {
  text-decoration: underline;
}

.btn:hover {
  color: inherit !important; /* Keeps the original color */
    background-color: inherit !important; /* Keeps the original background color */
    border-color: inherit !important; /* Keeps the original border color */
}